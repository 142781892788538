import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Box wrap=flex",
        "componentName": "Box",
        "wrap": "flex"
      }}>{`<Box>Look at me, being all boxed in</Box>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Box" mdxType="Props" />
    <h2 {...{
      "id": "alignself",
      "style": {
        "position": "relative"
      }
    }}>{`alignSelf`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex flexDirection="column">
  <Box alignSelf="flex-end">Flex-end</Box>
  <Box>normal</Box>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "bgcolor",
      "style": {
        "position": "relative"
      }
    }}>{`bgColor`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Sets the backgroundColor of the Boxe. It's important to know it will also try to
set the corresponding text color!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box bgColor="primaryBase">My text will get the coresponding color</Box>
<Box bgColor="blackBase">My text will get the coresponding color (white)</Box>
<Box bgColor="whiteBase">My text will get the coresponding color (black)</Box>
`}</code></pre>
    <h2 {...{
      "id": "border",
      "style": {
        "position": "relative"
      }
    }}>{`border`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box borderColor="primaryBase" borderStyle="solid" borderWidth={2} my={6}>
  border
</Box>
<Box borderTopColor="secondaryBase" borderTopStyle="solid" borderTopWidth={2} my={6}>
  borderTop
</Box>
<Box borderRightColor="tertiaryBase" borderRightStyle="solid" borderRightWidth={2} my={6}>
  borderRight
</Box>
<Box borderBottomColor="redBase" borderBottomStyle="solid" borderBottomWidth={2} my={6}>
  borderBottom
</Box>
<Box borderLeftColor="blueBase" borderLeftStyle="solid" borderLeftWidth={2} my={6}>
  borderLeft
</Box>
`}</code></pre>
    <h2 {...{
      "id": "borderradius",
      "style": {
        "position": "relative"
      }
    }}>{`borderRadius`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderRadius={2} bgColor="primaryBase">
  A bit rounded
</Box>
<Box borderRadius="fill" bgColor="secondaryBase">
  Oooooooooval
</Box>
<Box borderRadius="sides" bgColor="tertiaryBase">
  Round and round
</Box>
`}</code></pre>
    <h2 {...{
      "id": "bottom",
      "style": {
        "position": "relative"
      }
    }}>{`bottom`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box position="relative" bgColor="primaryBase" width="full" height={3}>
  <Box position="absolute" bottom={5} bgColor="blueDark">
    Bottom: space 5
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "clone",
      "style": {
        "position": "relative"
      }
    }}>{`clone`}</h2>
    <p>{`The Box component has a clone property to enable the usage of the clone element
method of React
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-api.html#cloneelement"
      }}>{`(<React.cloneElement()>)`}</a>{`.
NOTE: The CSS specificity relies on the import order. If you want the guarantee
that the wrapped component's style will be overridden, you need to import the
Box last.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box position="relative" bgColor="grey10" width="full" p={3} clone>
  <Paper elevation={3}>Paper with box props.</Paper>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`color`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box color="primaryBase">My color is better than yours.</Box>
<Box color="secondaryBase">My color is better than yours.</Box>
<Box color="tertiaryBase">My color is better than yours.</Box>
<Box bgColor="primaryBase" color="textPrimaryBase">My color is better than yours.</Box>
`}</code></pre>
    <h2 {...{
      "id": "flex",
      "style": {
        "position": "relative"
      }
    }}>{`flex`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Flex>
    <Box width="100%" height="100px" bgColor="primaryBase"></Box>
    <Box flex="0 0 auto" width="20%" height="100px" bgColor="secondaryBase"></Box>
  </Flex>
  <Flex>
    <Box width="20%" height="100px" bgColor="primaryBase"></Box>
    <Box flex="2 0 auto" width="20%" height="100px" bgColor="secondaryBase"></Box>
  </Flex>
`}</code></pre>
    <h2 {...{
      "id": "flexbasis",
      "style": {
        "position": "relative"
      }
    }}>{`flexBasis`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex>
  <Box flexBasis="40%" width="100%" height="100px" bgColor="primaryBase"></Box>
  <Box flexBasis="60%" width="20%" height="100px" bgColor="secondaryBase"></Box>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "flexgrow",
      "style": {
        "position": "relative"
      }
    }}>{`flexGrow`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex>
  <Box width="20%" height="100px" bgColor="primaryBase"></Box>
  <Box flexGrow={2} width="20%" height="100px" bgColor="secondaryBase"></Box>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "flexshrink",
      "style": {
        "position": "relative"
      }
    }}>{`flexShrink`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex>
  <Box width="100%" height="100px" bgColor="primaryBase"></Box>
  <Box flexShrink={0} width="20%" height="100px" bgColor="secondaryBase"></Box>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "float",
      "style": {
        "position": "relative"
      }
    }}>{`float`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box float="left" bgColor="primaryBase" height={11} mr={2} p={2}>
  <Paragraph>float left</Paragraph>
</Box>
<Box float="right" bgColor="secondaryBase" height={11} ml={2} p={2}>
  <Paragraph>float right</Paragraph>
</Box>
<Box float="none" bgColor="tertiaryBase" p={2}>
  <Paragraph>
float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none float none.
  </Paragraph>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "fontweight",
      "style": {
        "position": "relative"
      }
    }}>{`fontWeight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Controls the font-weight css attribute. Only 3 values are available. If a value
is missing, it will fallback on the more bold weight.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box fontWeight="regular">
  <Paragraph>
    Lorem ipsum dolor amet gastropub meditation tumblr hashtag. Meggings cornhole prism +1 sriracha authentic la croix street art af typewriter poke. Tumblr YOLO freegan thundercats fixie, craft beer shoreditch humblebrag. Ramps kinfolk roof party waistcoat ennui prism. Authentic edison bulb iceland letterpress yr.
  </Paragraph>
  <Paragraph>
    Lorem ipsum dolor amet gastropub meditation tumblr hashtag. Meggings cornhole prism +1 sriracha authentic la croix street art af typewriter poke. Tumblr YOLO freegan thundercats fixie, craft beer shoreditch humblebrag. Ramps kinfolk roof party waistcoat ennui prism. Authentic edison bulb iceland letterpress yr.
  </Paragraph>
</Box>
<Box fontWeight="medium">
 <Paragraph>
 Single-origin coffee hoodie edison bulb man braid la croix ethical fashion axe street art yr hammock. Asymmetrical roof party truffaut, forage fashion axe tacos gentrify narwhal raclette fingerstache. Hexagon austin affogato kale chips, jean shorts etsy sriracha banh mi forage waistcoat scenester next level small batch try-hard letterpress. Blue bottle taiyaki roof party helvetica selfies gochujang.
 </Paragraph>
  <Paragraph>
 Single-origin coffee hoodie edison bulb man braid la croix ethical fashion axe street art yr hammock. Asymmetrical roof party truffaut, forage fashion axe tacos gentrify narwhal raclette fingerstache. Hexagon austin affogato kale chips, jean shorts etsy sriracha banh mi forage waistcoat scenester next level small batch try-hard letterpress. Blue bottle taiyaki roof party helvetica selfies gochujang.
 </Paragraph>
</Box>
<Box fontWeight="bold">
  <Paragraph>
  Blog migas tbh man braid pitchfork vaporware, chicharrones selvage seitan subway tile jean shorts humblebrag post-ironic. Tattooed live-edge readymade, vice cold-pressed swag tousled lomo air plant. Edison bulb bitters actually banjo pok pok hammock single-origin coffee stumptown knausgaard direct trade pinterest yr locavore jean shorts.
  </Paragraph>
  <Paragraph>
  Blog migas tbh man braid pitchfork vaporware, chicharrones selvage seitan subway tile jean shorts humblebrag post-ironic. Tattooed live-edge readymade, vice cold-pressed swag tousled lomo air plant. Edison bulb bitters actually banjo pok pok hammock single-origin coffee stumptown knausgaard direct trade pinterest yr locavore jean shorts.
  </Paragraph>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "height",
      "style": {
        "position": "relative"
      }
    }}>{`height`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex justifyContent="space-between" style={{ height: 100 }}>
  <Box bgColor="primaryBase" height={3}>
    3
  </Box>
  <Box bgColor="secondaryBase" height={5}>
    5
  </Box>
  <Box bgColor="tertiaryBase" height="full">
    full
  </Box>
  <Box bgColor="redBase" height="50%">
    50%
  </Box>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "left",
      "style": {
        "position": "relative"
      }
    }}>{`left`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box position="relative" bgColor="primaryBase" width="full" height={3}>
  <Box position="absolute" left={5} bgColor="blueDark">
    Left: space 5
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "m",
      "style": {
        "position": "relative"
      }
    }}>{`m`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box m={3} bgColor="secondaryBase">
    Margin around
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "mb",
      "style": {
        "position": "relative"
      }
    }}>{`mb`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box mb={3} bgColor="secondaryBase">
    Margin bottom
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "ml",
      "style": {
        "position": "relative"
      }
    }}>{`ml`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box ml={3} bgColor="secondaryBase">
    Margin left
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "mr",
      "style": {
        "position": "relative"
      }
    }}>{`mr`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box mr={3} bgColor="secondaryBase">
    Margin right
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "mt",
      "style": {
        "position": "relative"
      }
    }}>{`mt`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box mt={3} bgColor="secondaryBase">
    Margin top
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "mx",
      "style": {
        "position": "relative"
      }
    }}>{`mx`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box mx={3} bgColor="secondaryBase">
    Margin left and right
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "my",
      "style": {
        "position": "relative"
      }
    }}>{`my`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box borderColor="primaryBase" borderWidth={1} borderStyle="solid">
  <Box my={3} bgColor="secondaryBase">
    Margin top and bottom
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "order",
      "style": {
        "position": "relative"
      }
    }}>{`order`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex>
  <Box order={1} bgColor="primaryBase">
    1 order 1
  </Box>
  <Box order={2} bgColor="secondaryBase">
    2 order 2
  </Box>
  <Box order={0} bgColor="tertiaryBase">
    3 order 0
  </Box>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "p",
      "style": {
        "position": "relative"
      }
    }}>{`p`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box p={3} bgColor="primaryBase">
  Padding around
</Box>
`}</code></pre>
    <h2 {...{
      "id": "pb",
      "style": {
        "position": "relative"
      }
    }}>{`pb`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box pb={3} bgColor="primaryBase">
  Padding bottom
</Box>
`}</code></pre>
    <h2 {...{
      "id": "pl",
      "style": {
        "position": "relative"
      }
    }}>{`pl`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box pl={3} bgColor="primaryBase">
  Padding left
</Box>
`}</code></pre>
    <h2 {...{
      "id": "position",
      "style": {
        "position": "relative"
      }
    }}>{`position`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box position="relative" width="full" height="300px" bgColor="grey10">
  <Box bgColor="primaryBase" top={0} right={0} p={5} position="relative">
    relative
  </Box>
  <Box bgColor="secondaryBase" top={0} right={0} p={5} position="absolute">
    absolute
  </Box>
  <Box
    bgColor="tertiaryBase"
    top={0}
    right={0}
    p={5}
    // position="fixed"
  >
    fixed (commented)
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "pr",
      "style": {
        "position": "relative"
      }
    }}>{`pr`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box pr={3} bgColor="primaryBase">
  Padding right
</Box>
`}</code></pre>
    <h2 {...{
      "id": "pt",
      "style": {
        "position": "relative"
      }
    }}>{`pt`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box pt={3} bgColor="primaryBase">
  Padding top
</Box>
`}</code></pre>
    <h2 {...{
      "id": "px",
      "style": {
        "position": "relative"
      }
    }}>{`px`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box px={3} bgColor="primaryBase">
  Padding left and right
</Box>
`}</code></pre>
    <h2 {...{
      "id": "py",
      "style": {
        "position": "relative"
      }
    }}>{`py`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box py={3} bgColor="primaryBase">
  Padding top and bottom
</Box>
`}</code></pre>
    <h2 {...{
      "id": "right",
      "style": {
        "position": "relative"
      }
    }}>{`right`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box position="relative" bgColor="primaryBase" width="full" height={3}>
  <Box position="absolute" right={5} bgColor="secondaryBase">
    Right: space 5
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "textalign",
      "style": {
        "position": "relative"
      }
    }}>{`textAlign`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Controls the css text-align attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box textAlign="left">
  <Paragraph>
    Lorem ipsum dolor amet gastropub meditation tumblr hashtag. Meggings cornhole prism +1 sriracha authentic la croix street art af typewriter poke. Tumblr YOLO freegan thundercats fixie, craft beer shoreditch humblebrag. Ramps kinfolk roof party waistcoat ennui prism. Authentic edison bulb iceland letterpress yr.
  </Paragraph>
    <Paragraph>
    Lorem ipsum dolor amet gastropub meditation tumblr hashtag. Meggings cornhole prism +1 sriracha authentic la croix street art af typewriter poke. Tumblr YOLO freegan thundercats fixie, craft beer shoreditch humblebrag. Ramps kinfolk roof party waistcoat ennui prism. Authentic edison bulb iceland letterpress yr.
  </Paragraph>
</Box>
<Box textAlign="center">
 <Paragraph>
 Single-origin coffee hoodie edison bulb man braid la croix ethical fashion axe street art yr hammock. Asymmetrical roof party truffaut, forage fashion axe tacos gentrify narwhal raclette fingerstache. Hexagon austin affogato kale chips, jean shorts etsy sriracha banh mi forage waistcoat scenester next level small batch try-hard letterpress. Blue bottle taiyaki roof party helvetica selfies gochujang.
 </Paragraph>
  <Paragraph>
 Single-origin coffee hoodie edison bulb man braid la croix ethical fashion axe street art yr hammock. Asymmetrical roof party truffaut, forage fashion axe tacos gentrify narwhal raclette fingerstache. Hexagon austin affogato kale chips, jean shorts etsy sriracha banh mi forage waistcoat scenester next level small batch try-hard letterpress. Blue bottle taiyaki roof party helvetica selfies gochujang.
 </Paragraph>
</Box>
<Box textAlign="right">
  <Paragraph>
  Blog migas tbh man braid pitchfork vaporware, chicharrones selvage seitan subway tile jean shorts humblebrag post-ironic. Tattooed live-edge readymade, vice cold-pressed swag tousled lomo air plant. Edison bulb bitters actually banjo pok pok hammock single-origin coffee stumptown knausgaard direct trade pinterest yr locavore jean shorts.
  </Paragraph>
   <Paragraph>
  Blog migas tbh man braid pitchfork vaporware, chicharrones selvage seitan subway tile jean shorts humblebrag post-ironic. Tattooed live-edge readymade, vice cold-pressed swag tousled lomo air plant. Edison bulb bitters actually banjo pok pok hammock single-origin coffee stumptown knausgaard direct trade pinterest yr locavore jean shorts.
  </Paragraph>
</Box>
<Box textAlign="justify">
  <Paragraph>
    Lorem ipsum dolor amet gastropub meditation tumblr hashtag. Meggings cornhole prism +1 sriracha authentic la croix street art af typewriter poke. Tumblr YOLO freegan thundercats fixie, craft beer shoreditch humblebrag. Ramps kinfolk roof party waistcoat ennui prism. Authentic edison bulb iceland letterpress yr.
  </Paragraph>
   <Paragraph>
    Lorem ipsum dolor amet gastropub meditation tumblr hashtag. Meggings cornhole prism +1 sriracha authentic la croix street art af typewriter poke. Tumblr YOLO freegan thundercats fixie, craft beer shoreditch humblebrag. Ramps kinfolk roof party waistcoat ennui prism. Authentic edison bulb iceland letterpress yr.
  </Paragraph>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "top",
      "style": {
        "position": "relative"
      }
    }}>{`top`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Box position="relative" bgColor="primaryBase" width="full" height={3}>
  <Box position="absolute" top={5} bgColor="secondaryBase">
    Top: space 5
  </Box>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "width",
      "style": {
        "position": "relative"
      }
    }}>{`width`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box width="full" bgColor="primaryBase">
  "full"
</Box>
<Box width="70%" bgColor="secondaryBase">
  "70%"
</Box>
<Box width="300px" bgColor="secondaryBase">
  "300px"
</Box>
<Box width={11} bgColor="tertiaryBase">
  5 (token value)
</Box>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      